// eslint-disable-next-line
import React from "react"
/** @jsx jsx */
import { jsx, css} from '@emotion/core'
import Layout from "../components/layout"
import { Container, Row, Col } from "../components/html/Layout";
import { H1, P } from "../components/html/Fonts";
import { Link } from "../components/html/Links";
import { baseStyles } from "../components/html/_config";

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Row>
        <Col css={css`
          text-align: center;
          margin-bottom: 10%;
        `}>
          <H1>
            Sorry, This Page Does Not Exist!
          </H1>
          <P>Click <Link to='/' css={css`color: ${baseStyles.colorPrimary};`}>here</Link> to navigate back to the home page.</P>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default NotFoundPage
